@media print {
  html, body {
    margin: 0 !important;
    padding: 0 !important;
  }
  header, footer {
    display: none !important;
  }
  main {
    color: #000;
    padding: 0 !important;
  }
  button {
    display: none !important;
  }
}